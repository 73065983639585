.gallery-container {
    text-align: center;
    padding: 20px;
    width: 70%;
    margin: 10vh auto;
}

.gallery-title {
    font-size: 2rem;
    margin-bottom: 30px;
    color: #000;
}

.gallery-grid {
    display: flex;
    margin: 40px 0;
    flex-wrap: wrap;
}

.gallery-item {
    position: relative;
    cursor: pointer;
    transform: translateY(20px); /* Initially shifted down */
    transition: transform 2s; /* Smooth transition */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 30%;
    margin: 1%;
}

.gallery-item.visible {
    opacity: 1; /* Fully visible */
    transform: translateY(0); /* No shift */
}

.gallery-item img {
    width: 100%;
    height: 220px;
    display: block;
    border-radius: 2px;
}

.gallery-item img:hover {
    transform: scale(1.02);
    transition-duration: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* Modal styles */
.modal {
    display: none; /* Hidden by default */
    position: fixed; 
    z-index: 1; 
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.9);
    align-items: center;
    justify-content: center;
}

.modal-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
}

.modal-content, .close {
    animation-name: zoom;
    animation-duration: 1s;
}

@keyframes zoom {
    from {transform: scale(0.8)} 
    to {transform: scale(1)}
}

.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #fff;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

@media only screen and (max-width: 700px) {
    .modal-content {
        width: 100%;
    }
}

.see-more-button {
    background-color: #DD3C3B;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1rem;
}

@media (max-width: 768px) {
    .gallery-container {
        width: 90%;
    }

}

/* Mobile view */
@media (max-width: 480px) {
    .gallery-container {
        width: 90%;
    }

    .gallery-item {
        width: 46%;
        margin: 5px auto;
    }

    .gallery-item img {
        height: 180px;
    }
}
