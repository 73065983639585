.container {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    width: 100%;
    background: url('../../assests/landing2.jpg') no-repeat center center/cover;
    height: 100vh;
    animation: backgroundZoom 3s forwards;
}

@keyframes backgroundZoom {
    0% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

.image-section {
    flex: 1;
    overflow: hidden;
}

.image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.text-section {
    flex: 1;
    padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

.text-box {
    background-color: #FFFDFD;
    padding: 6% 0;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding-left: 10%;
}

.landing-sub-title {
    font-size: 16px;
    color: #6c6c6c;
    margin: 0;
}

.landing-title {
    font-size: 32px;
    color: #333;
    margin-bottom: 20px;
    margin: 0;
    width: 90%;
}

.landing-description {
    font-size: 16px;
    color: #333333;
    margin-bottom: 20px;
    width: 80%;
}

.quote-button {
    padding: 20px 35px;
    background-color: #3671B2;
    color: white;
    border: none;
    border-radius: 2px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.quote-button:hover {
    background-color: rgb(1, 61, 125);
}

/* Responsive Design */
@media (max-width: 768px) {
    .container {
        flex-direction: column;
    }

    .text-section {
        padding: 20px;
    }

    .landing-title {
        font-size: 28px;
    }
}

@media (max-width: 480px) {
    .text-section {
        padding: 15px;
    }

    .landing-title {
        font-size: 24px;
    }

    .quote-button {
        padding: 10px 20px;
        font-size: 14px;
    }
}
