.contact {
    margin: 50px 0;
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.contact.animate {
    opacity: 1;
    transform: translateY(0);
}

.contact-title {
    text-align: center;
    font-size: 32px;
    margin-bottom: 20px;
}

.contact-container {
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    overflow: hidden;
    width: 70%;
    margin: 0 auto;
}

.form-section {
    flex: 1.5;
    margin-right: 20px;
}

.form-title{
    font-size: 32px;
    margin-bottom: 10px;
}

.form-description {
    color: #090909 !important;
    margin-bottom: 40px;
    font-size: 16px;
}

.form-group {
    display: flex;
    flex-direction: row;
}

.input-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}

.input-container:last-child {
    margin-right: 0;
}

.input-container input,
.input-container textarea {
    padding: 20px;
    border: none;
    border-radius: 4px;
    background-color: #eaeaea;
    margin-top: 10px;
    margin-bottom: 5px; /* Adjust margin to accommodate error message */
}

.input-container textarea {
    resize: none;
    height: 100px;
}

.error {
    color: red;
    font-size: 12px;
    margin-top: -5px;
    margin-bottom: 10px;
}

.status-message {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: green;
    margin-top: 20px;
    text-align: center;
}

.form-button {
    padding: 15px;
    width: 100%;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 10px;
}

.form-button:hover {
    background-color: #333;
}

.contact-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10%;
    margin-top: 100px;
}

.info-item {
    display: flex;
    align-items: start;
    margin-bottom: 40px;
}

.info-icon {
    width: 40px;
    margin-right: 10px;
}

.info-item p {
    margin: 0;
    font-weight: bold;
}

.info-item span {
    font-size: 14px;
    color: #666;
}

@media (max-width: 768px) {
    .contact-container {
        flex-direction: column;
        width: 96%;
    }

    .form-section {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .form-group {
        display: block; 
    }

    .input-container {
        margin-right: 0; 
    }

    .contact-info {
        margin-left: 10px;
    }
}
