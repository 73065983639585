@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#F6F6F6;
}

* {
  font-family: 'Poppins', sans-serif !important;
}

.container-title {
  font-size: 16px;
  color: #090909;
  margin: 0;
}

.container-heading {
  color: #090909;
  font-size: 32px;
  font-weight: 700;
  margin: 0;
}